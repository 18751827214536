.filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
  }
  
  .filter-select, .filter-input, .filter-button {
    height: 40px;
    padding: 0 10px;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .filter-select {
    width: 150px;
    border: 1px solid #ccc;
    appearance: none;
    background-color: #fff;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  }